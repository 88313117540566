export default {
  // 全部园区搜索页
  getParkList(data) {
    return {
      method: 'POST',
      url: '/park/list',
      data
    };
  },
  // 园区详情
  getParkDetail(data) {
    return {
      method: 'POST',
      url: '/park/detail',
      data
    };
  },
  // 园区主页-焦点园区
  gethomeList(data) {
    return {
      method: 'POST',
      url: '/park/query/homeList',
      data
    };
  },
  // 园区详情-焦点园区
  getFocusList(data) {
    return {
      method: 'POST',
      url: '/park/focusList',
      data
    };
  },
  // 全部园区-选项配置
  getbaseConfig(data) {
    return {
      method: 'POST',
      url: '/base/config/get',
      data
    };
  },
  // 选项配置-数组
  getConfigList(data) {
    return {
      method: 'POST',
      url: '/base/config/getList',
      data
    };
  },
  // 园区详情-周边推荐园区列表
  getNearList(data) {
    return {
      method: 'POST',
      url: '/park/nearList',
      data
    };
  },
  // 通用关注接口-关注操作
  favAdd(data) {
    return {
      method: 'POST',
      url: '/user/fav/add',
      data
    };
  },
  // 园区申请入驻
  apply(data) {
    return {
      method: 'POST',
      url: '/park/company/apply',
      data
    };
  },
  // 园区合作申请
  cooperate(data) {
    return {
      method: 'POST',
      url: '/park/cooperate',
      data
    };
  },
  // 是否已申请入驻
  hasApply(data) {
    return {
      method: 'POST',
      url: '/park/hasApply',
      data
    };
  },
  // 全部政策搜索页
  getPolicyList(data) {
    return {
      method: 'POST',
      url: '/policy/search',
      data
    };
  },
  // 政策详情
  getPolicyDetail(data) {
    return {
      method: 'POST',
      url: '/policy/detail',
      data
    };
  },
  // 热门政策详情
  getLikePolicy(data) {
    return {
      method: 'POST',
      url: '/policy/likePolicy',
      data
    };
  },
  // 地区分类统计
  getAreaCatSummary(data) {
    return {
      method: 'POST',
      url: '/policy/areaCatSummary',
      data
    };
  },
  // 热门政策
  getHotPolicyRead() {
    return {
      method: 'POST',
      url: '/policy/hotPolicyRead',
    };
  },
  // 用户政策匹配列表
  getUserPolicyMatchList() {
    return {
      method: 'POST',
      url: '/policy/userPolicyMatchList',
    };
  },
  //获得政策匹配结果
  getPolicyReadMatchResult(data) {
    return {
      method: 'POST',
      url: '/policy/policyReadMatchResult',
      data
    };
  },
  //提交政策解读匹配
  getCommitPolicyReadMatch(data) {
    return {
      method: 'POST',
      url: '/policy/commitPolicyReadMatch',
      data
    };
  },
  //提交政策解读匹配2
  getPolicyApply(data) {
    return {
      method: 'POST',
      url: '/policy/apply',
      data
    };
  },
  //删除政策匹配
  delPolicyReadMatch(data) {
    return {
      method: 'POST',
      url: '/policy/delPolicyReadMatch',
      data
    };
  },
}
export default {
    // 企业查询
    getCompanyList(data) {
        return {
            url: '/ent/query/list',
            data
        }
    },
    // 活跃企业查询
    getActiveEnt(data) {
        return {
            url: '/industry/ent/active',
            data
        }
    },
    // 通过编码搜索产业链数据
    getQueryByCode(data) {
        return {
            url: '/industry/extension/queryByCode',
            data: {
                data
            }
        }
    },
    // 返回搜索关键词产业链列表
    getqueryByKeyword(data) {
        return {
            url: '/industry/extension/queryByKeyword',
            data: {
                data
            }
        }
    },
    // 精准搜客
    getPreciselist(data) {
        return {
            url: '/ent/query/list/precise',
            data
        }
    },
    // 查询精准搜客筛选条件
    getCondition() {
        return {
            url: '/ent/condition/precision'
        }
    },
    // 查询条件组
    getConditionGroup() {
        return {
            url: '/ent/condition/get/precision'
        }
    },
    // 保存条件组
    saveConditionGroup(data) {
        return {
            url: '/ent/condition/save/precision',
            data
        }
    },
    // 删除条件组
    delConditionGroup(id) {
        return {
            url: '/ent/condition/delete/precision',
            data: {
                id
            }
        }
    },
    // 国民经济行业分类
    getCategoryTree() {
        return {
            url: '/ent/condition/categoryTree'
        }
    },
    // 企业主页详情
    getCompanyDetail(data) {
        return {
            url: '/ent/query/detail',
            data
        }
    },
    // 企业主页-股东信息
    getShareholder(data) {
        return {
            url: '/ent/query/shareholder',
            data
        }
    },
    // 企业主页-财务报表
    getFinanceReport(data) {
        return {
            url: '/ent/query/financeReport',
            data
        }
    },
    // 企业主页-上市基本信息
    getListingInfo(data) {
        return {
            url: '/ent/query/listingInfo',
            data
        }
    },
    // 产品类别等级
    getLevel(data) {
        return {
            url: '/goods_category/level',
            data
        }
    },
    // 产品类别按上级查询
    getLevelParent(data) {
        return {
            url: '/goods_category/parent',
            data
        }
    },
    // 需求市场-平台动态（最新需求动态）
    getDynamic(data) {
        return {
            url: '/demand/dynamic',
            data
        }
    },
    // 需求市场-平台动态（最新需求动态）
    demandSearch(data) {
        return {
            url: '/demand/search',
            data
        }
    },
    // 需求市场-销售需求详情
    saleDetail(data) {
        return {
            url: '/demand/sale/detail',
            data
        }
    },
    // 需求市场-需求询价
    enquiry(data) {
        return {
            url: '/demand/enquiry',
            data
        }
    },
    // 需求市场-需求的相关需求推荐
    recomDemand(data) {
        return {
            url: '/demand/recommend/ref',
            data
        }
    },
    // 需求市场-采购需求详情
    purchaseDetail(data) {
        return {
            url: '/demand/purchase/detail',
            data
        }
    },
    // 需求市场-基本信息
    baseInfo(data) {
        return {
            url: '/personnel/baseInfo',
            data
        }
    },
    // 用户一键解锁
    unlockAdd(data) {
        return {
            url: '/user/unlock/add',
            data
        }
    },
    // 人才主页- 全部信息
    allInfo(data) {
        return {
            url: '/personnel/allInfo',
            data
        }
    },
    // 人才主页-发布的需求
    demandUser(data) {
        return {
            url: '/demand/by_user',
            data
        }
    },
    // 人才主页-用户是否已关注指定人才
    hasFavUser(data) {
        return {
            url: '/personnel/hasFavUser',
            data
        }
    },
    // 企业主页-联系人
    getContact(data) {
        return {
            url: '/ent/query/contact',
            data
        }
    },
    // 企业主页-融资列表-分页
    financingInfo(data) {
        return {
            url: '/ent/query/financingInfo',
            data
        }
    },
    // 企业主页-招聘信息-分页
    getJob(data) {
        return {
            url: '/ent/query/job',
            data
        }
    },
    // 企业主页-行政许可-分页
    getLicense(data) {
        return {
            url: '/ent/query/license',
            data
        }
    },
    // 企业主页-裁判文书-分页
    judgeDoc(data) {
        return {
            url: '/ent/query/judgeDoc',
            data
        }
    },
    // 企业主页-软件著作权-分页
    copyright(data) {
        return {
            url: '/ent/query/copyright',
            data
        }
    },
    // 企业主页-行政处罚-分页
    adminPenalty(data) {
        return {
            url: '/ent/query/adminPenalty',
            data
        }
    },
    // 企业主页-专利信息-分页
    patentInfo(data) {
        return {
            url: '/ent/query/patentInfo',
            data
        }
    },
    // 企业主页-产品列表-分页
    product(data) {
        return {
            url: '/ent/query/product',
            data
        }
    },
    // 企业主页-商标-分页
    trademark(data) {
        return {
            url: '/ent/query/trademark',
            data
        }
    },
    // 企业主页-网站备案-分页
    websiteRecord(data) {
        return {
            url: '/ent/query/websiteRecord',
            data
        }
    },
    // 企业主页-企业需求
    companyDemand(data) {
        return {
            url: '/demand/by_company',
            data
        }
    },
    // 企业主页-数量统计
    countInfo(data) {
        return {
            url: '/ent/query/countInfo',
            data
        }
    },
    // 企业主页-核心人员-分页
    keyPersonnel(data) {
        return {
            url: '/ent/query/keyPersonnel',
            data
        }
    },
    // 企业主页-作品著作权-分页
    opusCopyright(data) {
        return {
            url: '/ent/query/opusCopyright',
            data
        }
    },
    // 企业主页-企业业务-分页
    brandProject(data) {
        return {
            url: '/ent/query/brandProject',
            data
        }
    },
    // 企业主页-税务信用-分页
    tax(data) {
        return {
            url: '/ent/query/tax',
            data
        }
    },
    // 企业主页-用户一键解锁判断
    validateLock(data) {
        return {
            url: '/user/unlock/validate',
            data
        }
    },
    // 需求id为0
    queryDemand(data) {
        return {
            url: '/demand/querySpiderUser',
            data
        }
    },
    // 需求筛选地址
    addressDemandList(data) {
        return {
            method: 'POST',
            url: "/demand/search/address",
            data
        }
    },
}
// web service api 业务处理
// 此模块用于扩展updata功能
export default {
  sendSms(data) {
      return {
          method: 'POST',
          url: '/sms/send',
          data
      };
  },
  pwdLogin(data) {
      return {
          method: 'POST',
          url: "/user/login/pwd",
          data
      }
  },
  quickLogin(data) {
      return {
          method: 'POST',
          url: "/sms/check",
          data
      }
  },
  updatePwd(data) {
      return {
          method: 'POST',
          url: "/user/back/pwd",
          data
      }       
  },
  userLoginInfo(data) {
    return {
        method: 'POST',
        url: "/user/my/info",
        data
    }        
  },
  addrList(data) {
    return {
        method: 'POST',
        url: "/address/list/existPark",
        data
    }        
  },
  // 设置密码
  setPwd(data) {
    return {
        method: 'POST',
        url: "/user/set/pwd",
        data
    }        
  },
  // 埋点上报
  logReport(data) {
    return {
        method: 'POST',
        url: "http://192.168.10.161/log/pc",
        data
    }        
  },
}
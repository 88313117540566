import Request from "./request";
import home from './home'
import ucenter from './ucenter'
import user from './user'
import zone from './zone'
import bigdata from './bigdata'
import exchange from './exchange'
import ressources from './ressources'
import industryService from './industryService'
import abutment from './abutment'
const service = [
    home,
    ucenter,
    zone,
    user,
    bigdata,
    exchange,
    ressources,
	industryService,
	abutment
]
service.forEach(item => {
    Request.assign(item)
})
if (window.A) {
    window.rxConfig && Request.init(rxConfig)
    A.assign({
        updata: Request
    })
}
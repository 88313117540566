// web service api 业务处理
// 此模块用于扩展updata功能
export default {
  userServiceApply(data) {
    return {
        method: 'POST',
        url: "/userService/apply",
        data
    }
  }
}
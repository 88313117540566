// web service api 业务处理
// 此模块用于扩展updata功能
export default {
    getUserInfo() {
        return {
            url: '/user/my/info'
        };
    },
    // 我的企业列表
    enterpriseList(data) {
        return {
          method: 'POST',
          url: '/user/enterprise/list',
          data
        };
      },
    getUserCard(){
        return {
            url: '/user/'
        };
    },
    // 用户中心-我已发布的需求分页列表
    getmyList(data){
        return {
            url: '/demand/my/list',
            data
        };
    },
    // 用户一键解锁企业列表查询
    unlockList(data){
        return {
            url: '/user/unlock/list',
            data
        };
	},
	// 用户一键解锁企业列表查询
    unlockCount(){
        return {
            url: '/user/unlock/count'
        };
    },
	getUserBaseInfo() {//个人资料基本信息
	    return {
	        url: '/user/edit/info/baseInfo'
	    };
	},
	editBaseSave(data) {//个人资料基本信息编辑修改
	    return {
			method: 'POST',
	        url: '/user/edit/base/save',
			data
	    };
	},
	workExpInfo() {//个人资料工作经历
	    return {
	        url: '/user/edit/info/workExpInfo'
	    };
	},
	workExpSave(data) {//个人资料工作经历编辑修改
	    return {
			method: 'POST',
	        url: '/user/edit/workExp/save',
			data
	    };
	},
	workExpDelete(data) {//个人资料工作经历删除
	    return {
			method: 'POST',
	        url: '/user/edit/workExp/delete',
			data
	    };
	},
	eduExpInfo() {//个人资料教育经历
	    return {
	        url: '/user/edit/info/eduExpInfo'
	    };
	},
	eduExpSave(data) {//个人资料教育经历编辑修改
	    return {
			method: 'POST',
	        url: '/user/edit/eduExp/save',
			data
	    };
	},
	eduExpDelete(data) {//个人资料教育经历删除
	    return {
			method: 'POST',
	        url: '/user/edit/eduExp/delete',
			data
	    };
	},
	personRealNameInfo(data) {//获取个人实名认证信息
	    return {
	        url: '/user/cert/get/personRealNameInfo'
	    };
	},
	getRealNameInfo(data) {//获取用户实名认证信息
	    return {
	        url: '/user/cert/getRealNameInfo'
	    };
	},
	personRealName(data) {//提交个人实名认证信息
	    return {
			method: 'POST',
	        url: '/user/cert/personRealName',
			data
	    };
	},
	//发布销售需求
	publishSale(data) {
		return {
		method: 'POST',
				url: '/demand/publish/sale',
		data
		};
},
	//发布采购需求
	publishPurchase(data) {
		return {
		method: 'POST',
				url: '/demand/publish/purchase',
		data
		};
},
	//上/下架需求
	upDown(data) {
		return {
		method: 'POST',
				url: '/demand/upDown',
		data
		};
},
	//编辑时回显我的销售需求
	saleInfo(data) {
		return {
		method: 'POST',
				url: '/demand/my/sale/info',
		data
		};
},
	//编辑时回显我的采购需求
	purchaseInfo(data) {
		return {
		method: 'POST',
				url: '/demand/my/purchase/info',
		data
		};
},
	//刷新需求
	refreshDemand(data) {
		return {
		method: 'POST',
				url: '/demand/refresh',
		data
		};
},
	// 用户中心 - 个人信息卡片
	getInfoCard(){
	    return {
			method: 'POST',
	        url: '/user/home/infoCard'
	    };
	},
	getInfoCardData(){
	    return {
			method: 'POST',
	        url: '/user/home/userData'
	    };
	},
	companyRealNameInfo(data) {//获取企业实名认证信息
	    return {
	        url: '/user/cert/get/companyRealNameInfo '
	    };
	},
	companyRealName(data) {//提交企业实名认证信息
	    return {
			method: 'POST',
	        url: '/user/cert/companyRealName',
			data
	    };
	},
	smsCheck(data) {//检测验证码
	    return {
			method: 'POST',
	        url: '/sms/check',
			data
	    };
	},
	userupdatePwd(data) {//修改密码
	    return {
			method: 'POST',
	        url: '/user/update/pwd',
			data
	    };
	},
	checkOldMobile(data) {// 检查旧手机
	    return {
			method: 'POST',
	        url: '/user/update/checkOldMobile',
			data
	    };
	},
	updateNewMobile(data) {// 更新新手机
	    return {
			method: 'POST',
	        url: '/user/update/updateNewMobile',
			data
	    };
	},
	allReading(data) {// 全部已读
	    return {
			method: 'POST',
	        url: '/letter/message/all/reading',
			data
	    };
	},
	categoryPage(data) {// 站内信分页
	    return {
			method: 'POST',
	        url: '/letter/message/category/page',
			data
	    };
	},
	oneReading(data) {// 站内信分页
	    return {
			method: 'POST',
	        url: '/letter/message/one/reading',
			data
	    };
	},
	getInfoDemand(){//用户中心 - 我的需求
	    return {
			method: 'POST',
	        url: '/demand/my/type/statistics'
	    };
	},
	feedbackSave(data){//用户中心 - 用户反馈
	    return {
			method: 'POST',
			url: '/user/feedback/save',
			data
	    };
	},
	favEnterpriseList(data){//用户中心 - 我关注的企业列表
	    return {
			method: 'POST',
	    url: '/fav/enterpriseList',
			data
	    };
	},
	favExpertList(data){//用户中心 - 我关注的行家列表
	    return {
				method: 'POST',
				url: '/fav/expertList',
				data
	    };
	},
	favPersonnelList(data){//用户中心 - 我关注的人才列表
	    return {
				method: 'POST',
	      url: '/fav/personnelList',
				data
	    };
	},
	favReportList(data){//用户中心 - 我收藏的研报列表
		return {
			method: 'POST',
			url: '/fav/reportList',
			data
		};
	},
	favReportList(data){//用户中心 - 我收藏的研报列表
		return {
			method: 'POST',
			url: '/fav/reportList',
			data
		};
	},
	favLiveList(data){//用户中心 - 我收藏的直播列表
		return {
			method: 'POST',
			url: '/fav/liveList',
			data
		};
	},
	favPolicyList(data){//用户中心 - 我收藏的政策列表
		return {
			method: 'POST',
			url: '/fav/policyList',
			data
		};
	},
	favParkList(data){//用户中心 - 我收藏的园区列表
		return {
			method: 'POST',
			url: '/fav/parkList',
			data
		};
	},
	getCompamyList(data){//用户中心 - 根据关键词返回企业列表
		return {
			method: 'POST',
			url: '/diagnose/ent/getCompamyList',
			data
		};
	},
	unlockCount(data){//用户一键解锁数量
		return {
			method: 'POST',
			url: '/user/unlock/count',
			data
		};
	},
	manualAdd(data){//手动添加我的企业
		return {
			method: 'POST',
			url: '/user/enterprise/manualAdd',
			data
		};
	},
	delCompany(data){//删除我的企业
		return {
			method: 'POST',
			url: '/user/enterprise/del',
			data
		};
	},
	completeName(data){//完善我的企业名称
		return {
			method: 'POST',
			url: '/user/enterprise/completeName',
			data
		};
	},
	unreadcount(){//用户中心 - 消息通知未读状态
		return {
			method: 'POST',
			url: '/letter/message/unread/count'
		};
	},
	delDemand(data){//用户中心 - 删除需求
		return {
			method: 'POST',
			url: '/demand/delete',
			data
		};
	}
}
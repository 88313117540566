// web service api 业务处理
// 此模块用于扩展updata功能
export default {
  // 问题列表
  circleAsk(data) {
    return {
        method: 'POST',
        url: "/circle/ask/homeList",
        data
    }
  },
  circleGroup(data) {
      return {
          method: 'POST',
          url: "/circle/group/all/list",
          data
      }
  },
  circleGroupById(data) {
    return {
        method: 'POST',
        url: "/circle/ask/getListByGroupId",
        data
    }
  },
  circleGroupHot(data) {
    return {
        method: 'POST',
        url: "/circle/group/hot",
        data
    }
  },
  circleAskRecommendHot(data) {
    return {
        method: 'POST',
        url: "/circle/ask/recommend",
        data
    }
  },
  groupDetail(data) {
    return {
        method: 'POST',
        url: "/circle/group/detail",
        data
    }
  },
  askDetail(data) {
    return {
        method: 'POST',
        url: "/circle/ask/detail",
        data
    }
  },
  askHomeRandom(){
    return {
      method: 'POST',
      url: "/circle/ask/cyzxHomeAskRandom"
    }
  },
  likeSave(data) {
    return {
        method: 'POST',
        url: "/user/like/save",
        data
    }
  },
  
  // 行业研报
  reportSearch(data) {
    return {
        method: 'POST',
        url: "/report/search",
        data
    }
  },
  // 随机查询产业资讯列表  500
  newsRandomList(data) {
    return {
        method: 'POST',
        url: "/news/randomList",
        data
    }
  },
  // 搜索资讯分页列表   500
  newsSearchList(data) {
    return {
        method: 'POST',
        url: "/news/searchList",
        data
    }
  },
  //产业资讯详情
  newsDetail(data) {
    return {
        method: 'POST',
        url: "/news/detail",
        data
    }
  },
  // 首页或产业交流展示-最新几条产业资讯（置顶优先） 500
  newsRecentList(data) {
    return {
        method: 'POST',
        url: "/news/recentList",
        data
    }
  },
  // 获取板块内容列表
  pageItemGet(data) {
    return {
        method: 'POST',
        url: "/base/pageItem/get",
        data
    }
  },
  // 首页  专家列表
  expertCyjlHomeExpert(data) {
    return {
        method: 'POST',
        url: "/expert/cyjlHomeExpert",
        data
    }
  },
   //专业咨询
   expertListRecommend(data) {
    return {
        method: 'POST',
        url: "/expert/zyzx/pcZyzxExpertRecommend",
        data
    }
  },
  // 专家详情
  expertInfoPageData(data) {
    return {
        method: 'POST',
        url: "/expert/expertInfoPageData",
        data
    }
  },
//  同领域专家推荐
  likeExperts(data) {
    return {
        method: 'POST',
        url: "/expert/likeExperts",
        data
    }
  },

  //  发布问题
  expertMeetAppointment(data) {
    return {
        method: 'POST',
        url: "/expert/meetAppointment",
        data
    }
  },
  //  用户是否关注行家
  userFavExpert(data) {
    return {
        method: 'POST',
        url: "/fav/get/userFavExpert",
        data
    }
  },
  addressList(data) {
    return {
        method: 'POST',
        url: "/address/list",
        data
    }
  },
  reportSearch(data){
    return {
      method: 'POST',
      url: "/report/search",
      data
    }
  },
  reportRandomList(data){
    return {
      method: 'POST',
      url: "/report/home/randomList",
      data
    }
  },
  reportClassify(data){
    return {
      method: 'POST',
      url: "/report/classify",
      data
    }
  },
  reportTop(data){
    return {
      method: 'POST',
      url: "/report/top",
      data
    }
  },
  reportDetail(data){
    return {
      method: 'POST',
      url: "/report/detail",
      data
    }
  },
  reportFile(data){
    return {
      method: 'POST',
      url: "/report/get/reportFile",
      data
    }
  },
  reportCommentList(data){
    return {
      method: 'POST',
      url: "/report/commentList",
      data
    }
  },
  reportHot(data){
    return {
      method: 'POST',
      url: "/report/hot",
      data
    }
  },
  expertGetLiveInfo(data){
    return {
      method: 'POST',
      url: "/expert/get/liveInfo",
      data
    }
  },
}
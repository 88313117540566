// web service api 业务处理
// 此模块用于扩展updata功能
export default {
    getIndustryCategory() {
        return {
            method: 'POST',
            url: "/industry/category/statistic",
            data: {
                data: 2
            }
        };
    },
    // 获取产业一级分类
    getCategoryLevelOne() {
        return {
            url: '/industry/category/level-one'
        }
    },
    // 获取具体产业链
    getCategoryStatistic(data) {
        return {
            url: '/industry/category/statistic',
            data: {
                data
            }
        }
    },
    // 产业地图数据
    getProvincesStatistic(data) {
        return {
            url: '/industry/provinces/statistic',
            data: {
                data
            }
        }
    },
    // 产业地图数据TOP5
    getProvincesStatisticTop5(data) {
        return {
            url: '/industry/provinces/statistic/top5',
            data: {
                data
            }
        }
    },
    // 产业需求统计
    getCountStatistics(data) {
        return {
            url: '/demand/type/count/statistics',
            data: {
                data
            }
        }
    },
    // 领域行家
    getIndustryCategoryExpert(data) {
        return {
            url: '/expert/industryCategoryExpert',
            data: {
                data,
                pageNum: 1,
                pageSize: 4
            }
        }
    }
}
// web service api 业务处理
// 此模块用于扩展updata功能
export default {
    getPageItem(data) {
        return {
            method: 'POST',
            url: '/base/pageItem/get',
            data
        };
    },
    //最新销售需求
    getLatestSale(data) {
        return {
            method: 'POST',
            url: '/demand/latest/sale',
            data
        };
    },
    //最新采购需求
    getLatestPurchase(data) {
        return {
            method: 'POST',
            url: '/demand/latest/purchase',
            data
        };
    },
    // 精选需求推荐
    getDemandRecommend() {
        return {
            method: 'POST',
            url: '/demand/recommend/choose'
        };
    },
    // 行业研报
    getHomeReport() {
        return {
            method: 'POST',
            url: '/report/home/list'
        };
    },
    // 问答列表
    getCircleAsk() {
        return {
            method: 'POST',
            url: '/circle/ask/home/list'
        };
    },
    // 案例
    getCase(topNum) {
        return {
            method: 'GET',
            url: '/successfulCase/top',
            data: {
                topNum
            }
        };
    },
    // 优质企业
    getBestCompany() {
        return {
            method: 'POST',
            url: '/ent/best/ent'
        };
    },
    //wap 优质企业
    getWapBestCompany(data) {
        return {
            method: 'POST',
            url: '/ent/wap/best/ent ',
            data
        };
    },   
    // 直播活动
    getIndustryHomeList(){
        return {
            method: 'POST',
            url: '/industry/dock/live/homeList'
        };
    }
}
import Request from "./request";
export default {
    http: null,
    send: function (reqData, apiname) {
        // 是否自动创建loading遮罩弹窗
        let autoLoading = typeof reqData.autoLoading != 'undefined' ? reqData.autoLoading : true;
        if (reqData.data && typeof reqData.data.autoLoading != 'undefined') {
            autoLoading = reqData.data.autoLoading;
            delete reqData.data.autoLoading;
        }
        return new Promise((resolve, reject) => {
            // 自动添加loading
            if (autoLoading) A.autoLoading(true,apiname);
            this.http.RS(reqData).then(data => {
                if (autoLoading) A.autoLoading(false,apiname);
                if (data.code == '10000') {
                    resolve(data, 'yes');
                } else if (data.code == '9999') {
                    // token失效清除
                    A.db.token = "";
                    A.confirm("请登录",ok=>{
                        if(ok=='confirm'){
                            A.gotoLogin();
                        }
                    })
                } else if (parseInt(data.code) >= 1000000) {
                    resolve(data, 'no');
                } else {
                    if (window && window.rxConfig && window.rxConfig.isWap) {
                        A.Toast(data.message);
                    } else {
                        A.message(data.message)
                    }
                }
            }, err => {
                if (autoLoading) A.autoLoading(false,apiname);
                console.log(err)
                reject(err);
            });
        })
    },
    assign(obj) {
        let Mkeys = Object.keys(this);
        let Okeys = Object.keys(obj);
        let keys = _.intersection(Mkeys, Okeys);
        if (keys.length > 0) {
            throw '存在相同的接口请求方法' + JSON.stringify(keys);
        } else {
            for (let key of Okeys) {
                Object.assign(this, {
                    [key]() {
                        return this.send(obj[key](...arguments), key)
                    }
                })
            }
        }
    },
    init(config) {
        this.http = Request.httpService(config)
    }
}
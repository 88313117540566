// web service api 业务处理
// 此模块用于扩展updata功能
export default {
    liveAllList() { //线上培训课程轮播
        return {
            url: '/industry/dock/live/allList'
        };
    },
    liveDetail(data){  //获取直播详情
        return {
            url: '/industry/dock/live/detail',
            data
        };
    },
	liveRecommendList(data) { //直播推荐
	    return {
			url: '/industry/dock/live/recommendList',
			data
	    };
	},
	meetingAllList() { //线下会议活动
	    return {
	        url: '/meeting/allList'
	    };
	},
	meetingDetail(data){  //会议详情
	    return {
	        url: '/meeting/detail',
	        data
	    };
	},
	meetingRecommendList(data) { //会议推荐
	    return {
	        url: '/meeting/recommendList',
			data
	    };
	},
	abutmentBanner(data){  //获取板块内容列表
	    return {
	        url: '/base/pageItem/get',
	        data
	    };
	}
	
}